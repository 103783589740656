let mainOne = [
    {
        name: "anchovy , ",
        details: " CRISP HERBS, MUSTARD SEED, FENNEL, CHILLI, AIOLI, LEMON",
        price: "40",
        img_path: "/baron-menu-designs/Main Pics/01 Anchovy.jpg"
    },

    {
        name: "shrimp , ",
        details: " SHAWARMA BUTTER, SAFFRON YOGHURT, FLAT BREAD, BIWAZ",
        price: "70",
        img_path: "/baron-menu-designs/Main Pics/02 Shrimp.jpg"
    },

    // {
    //     name: "TARAMA , ",
    //     details: " WHIPPED COD ROE, LEMON, SUMAC, IKURA, SESAME FLAT BREAD",
    //     price: "55",
    //     img_path: "/baron-menu-designs/Main Pics/gray color.jpg"
    // },

    {
        name: "SCALLOP , ",
        details: " ON-SHELL, MISO BUTTER, SOY-LEMON DRESSING, SCALLION-CHUTNEY",
        price: "135",
        img_path: "/baron-menu-designs/Main Pics/04 Scallopi.jpg"
    },

    {
        name: "OCTOPUS , ",
        details: " LEMON, OREGANO, TOMATO CONFIT, CHICKPEA, SKORDALIA",
        price: "100",
        img_path: "/baron-menu-designs/Main Pics/05 Octopus.jpg"
    },

    {
        name: "CALAMARI , ",
        details: " CORIANDER, ZOUGH, LEMON, CHATTA",
        price: "80",
        img_path: "/baron-menu-designs/Main Pics/06 Calamari.jpg"
    },
]

let mainTwo = [

    {
        name: "KALE , ",
        details: " PUFFED-RICE, HERBS, RADISH, CUCUMBER, CAULIFLOWER, RAISIN, CITRUS DRESSING",
        price: "50",
        img_path: "/baron-menu-designs/Main Pics/17 Kale.jpg"
    },
    
    {
        name: "ZUCCHINI , ",
        details: " FETA, SHALLOT DRESSING, RADISH, PISTACHIO, CRISP SHALLOT, MINT",
        price: "50",
        img_path: "/baron-menu-designs/Main Pics/08 Zucchini.jpg"
    },

    {
        name: "POTATO , ",
        details: " CRISP CAKE, BOTARGA, LEMON ZA’ATAR PICKLE",
        price: "40",
        img_path: "/baron-menu-designs/Main Pics/09 Potato.jpg"
    },

    {
        name: "CARROTS , ",
        details: " HONEY VINEGAR GLAZE, PADRON CHUTNEY, FREEKEH, SMOKED YOGURT",
        price: "45",
        img_path: "/baron-menu-designs/Main Pics/10 Carrots.jpg"
    },

    {
        name: "MUSHROOM , ",
        details: " WHIPPED CHICKPEA, DUQQUA, HERB CHUTNEY, FLAT BREAD",
        price: "55",
        img_path: "/baron-menu-designs/Main Pics/11 Mushroom.jpg"
    },

    {
        name: "CORN ROASTED, ",
        details: " FETA, PEPPERS, ELOTE, SOFT HERBS, CHILLI, LEMON",
        price: "55",
        img_path: "/baron-menu-designs/Main Pics/12 Corn.jpg"
    },

    {
        name: "DATES , ",
        details: " SOUJOUK, BACON, TOMATO CHUTNEY, DIBS RUMMAN, PISTACHIO, CRISP HERBS",
        price: "65",
        img_path: "/baron-menu-designs/Main Pics/13 Date.jpg"
    },

    {
        name: "HALLOUMI , ",
        details: " ROASTED, PISTACHIO BUTTER, TOMATO CHUTNEY, ALLEPO BUTTER",
        price: "65",
        img_path: "/baron-menu-designs/Main Pics/14 Halloumi.jpg"
    },

    {
        name: "EGGPLANT , ",
        details: " WHOLE ROASTED, SOFT HERBS, FETA, PEPPER, PINE NUT",
        price: "55",
        img_path: "/baron-menu-designs/Main Pics/15 Eggplant.jpg"
    },

    {
        name: "CAULIFLOWER , ",
        details: " WHOLE ROASTED, “SPICED”, YOGHURT TAHINI, WALNUT, POMEGRANATE, ROSE",
        price: "95",
        img_path: "/baron-menu-designs/Main Pics/16 Cauliflower.jpg"
    },

    {
        name: "BEETROOT , ",
        details: " RISOTTO, FETA, PICKLED-WALNUT, SOFT HERBS",
        price: "65",
        img_path: "/baron-menu-designs/Main Pics/07 Beetroot.jpg"
    },
]

let mainThree = [

    {
        name: "CAPELLINI , ",
        details: " HANDMADE, SHRIMP TOUM, PARMESAN",
        price: "100",
        img_path: "/baron-menu-designs/Main Pics/18 Capellini.jpg"
    },

    {
        name: "CULURGIONES , ",
        details: " HINDBEH, ALEPPO BUTTER, TOMATO MARMALADE",
        price: "80",
        img_path: "/baron-menu-designs/Main Pics/19 Culurgiones.jpg"
    },

    {
        name: "SHISH BARAK , ",
        details: " BAHARAT BEEF, SMOKED YOGHURT, TOMATO , BASIL, PINENUT",
        price: "75",
        img_path: "/baron-menu-designs/Main Pics/20 Shish barak.jpg"
    },

    {
        name: "GNUDI , ",
        details: " ASHTA, PARSLEY, BASIL, PARMESAN, PINENUT",
        price: "70",
        img_path: "/baron-menu-designs/Main Pics/21 Gnudi.jpg"
    },
]

let mainFour = [

    {
        name: "SEABASS , ",
        details: " CRISP, CORIANDER, JADINIERE, AIOLI, BRIOCHE BUN",
        price: "75",
        img_path: "/baron-menu-designs/Main Pics/22 Hammour.jpg"
    },
    
    {
        name: "BAO , ",
        details: " LAMB BRISKET, TARATOR, DIBS RUMMAN, POMEGRANATE, FRIED CHALLOTS",
        price: "90",
        img_path: "/baron-menu-designs/Main Pics/23 Bao.jpg"
    }, 
]

let mainFive = [

    {
        name: "CHEEK , ",
        details: " WAGYU, OVERNIGHT, XÉRÈS, TOMATO, CELERIAC PURÉE, SAUCE GRIBICHE",
        price: "150",
        img_path: "/baron-menu-designs/Main Pics/24 Cheek.jpg"
    },

    {
        name: "SHANK , ",
        details: " LAMB OVERNIGHT, BAHARAT, MAFTOUL, PARSLEY SHALLOT SUMAC",
        price: "135",
        img_path: "/baron-menu-designs/Main Pics/25 Shank.jpg"
    },

    {
        name: "SIRLOIN , ",
        details: " WAGYU, 450GR ’BARON’ BUTTER, POTATO, SUCRINE SALAD",
        price: "350",
        img_path: "/baron-menu-designs/Main Pics/26 Sirloin.jpg"
    },

    {
        name: "POULET , ",
        details: " CHERMOULA, TOUM, DIRTY-RICE, MARKOUK, JUS",
        price: "115",
        img_path: "/baron-menu-designs/Main Pics/27 poulet.jpg"
    },

    {
        name: "DUCK , ",
        details: " CONFIT LEG, ROASTED BREAST, SOUJOUK-FREEKEH, POMEGRANATE",
        price: "155",
        img_path: "/baron-menu-designs/Main Pics/28 Duck.jpg"
    },

    {
        name: "RIBEYE , ",
        details: " WAGYU, 450GR ’BARON’ BUTTER, POTATO, SUCRINE SALAD",
        price: "350",
        img_path: "/baron-menu-designs/Main Pics/29 Ribeye.jpg"
    },

    {
        name: "FISH OF THE DAY , ",
        details: " WHOLE ROASTED 1KG, LEEK CAPER SALSA, CRISP SEEDS HERBS",
        price: "245",
        img_path: "/baron-menu-designs/Main Pics/30 Fish.jpg"
    },
]

 // ------------------------- ARABIC TEXT GOES HERE -------------------------------------------

let mainOneAr = [

    {
        name: " قريدس، ",
        details: "زبدة شاورما، زبادي بالزعفران، خبز بارون، بيواز",
        price: "70",
        img_path: "/baron-menu-designs/Main Pics/gray color.jpg"
    },

    {
        name: "أنشوجة مقلية، ",
        details: "أعشاب مقرمشة، بذور خردل، شمر، فلفل حار، أيولي، ليمون",
        price: "40",
        img_path: "/baron-menu-designs/Main Pics/01 Anchovy.jpg"
    },

    {
        name: "سكالوب، ",
        details: "سكالوب مع القشر، زبدة ميسو، صلصة البصل الأخضر، صويا وليمون",
        price: "135",
        img_path: "/baron-menu-designs/Main Pics/04 Scallopi.jpg"
    },

    // {
    //     name: "تراما، ",
    //     details: "كريمة سمك القد، ليمون، شطة، أعشاب ناعمة، بذور، بيتا منزلية",
    //     price: "55",
    //     img_path: "/baron-menu-designs/Main Pics/gray color.jpg"
    // },

    {
        name: "كالاماري، ",
        details: "ليمون، شطة",
        price: "80",
        img_path: "/baron-menu-designs/Main Pics/06 Calamari.jpg"
    },

    {
        name: "أخطبوط، ",
        details: "ليمون، أوريجانو، كونفيت الطماطم، حمص، بطاطا مهروسة مع ثوم",
        price: "100",
        img_path: "/baron-menu-designs/Main Pics/05 Octopus.jpg"
    },
]

let mainTwoAr = [

    {
        name: "كوسا، ",
        details: "فيتا، نعنع، تتبيلة الكراث، كراث مقلي، فستق",
        price: "50",
        img_path: "/baron-menu-designs/Main Pics/08 Zucchini.jpg"
    },

    {
        name: "كرنب، ",
        details: "ارز مقرمش، أعشاب، خيار، زهرة، زبيب، فجل، صلصة الحامض",
        price: "50",
        img_path: "/baron-menu-designs/Main Pics/17 Kale.jpg"
    },

    {
        name: "جزر، ",
        details: "خل العسل، صلصة بارون، فريكة، زبادي مدخن",
        price: "45",
        img_path: "/baron-menu-designs/Main Pics/10 Carrots.jpg"
    },

    {
        name: "بطاطا، ",
        details: "شرائح بطاطا مقلية مع صلصة مخلل الليمون والزعتر",
        price: "40",
        img_path: "/baron-menu-designs/Main Pics/09 Potato.jpg"
    },

    {
        name: "ذرة محمصة، ",
        details: "جبنة فيتا، فلفل، حبوب الذرة، أعشاب ناعمة، فلفل حار، ليمون",
        price: "55",
        img_path: "/baron-menu-designs/Main Pics/12 Corn.jpg"
    },

    {
        name: "فطر، ",
        details: "حمص مخفوق، دقة، صلصة الأعشاب، ليمون، خبز بارون",
        price: "55",
        img_path: "/baron-menu-designs/Main Pics/11 Mushroom.jpg"
    },

    {
        name: "حلومي، ",
        details: "حلوم مشوي، زبدة، فستق، صلصة الطماطم، زبدة حلبية",
        price: "65",
        img_path: "/baron-menu-designs/Main Pics/14 Halloumi.jpg"
    },

    {
        name: "تمر، ",
        details: "سجق، لحم مقدد، طماطم شاتني، دبس الرمان، فستق، أعشاب مقرمشة",
        price: "65",
        img_path: "/baron-menu-designs/Main Pics/gray color.jpg"
    },

    {
        name: "قرنبيط، ",
        details: "زهرة مشوية زبدة مبهرة، طراطور طحينة باللبن، صلصة الجوز، رمان، ورقيات، بتلة الورد",
        price: "95",
        img_path: "/baron-menu-designs/Main Pics/16 Cauliflower.jpg"
    },

    {
        name: "باذنجان، ",
        details: "باذنجان مشوي، أعشاب مقرمشة، فليفلة، صنوبر",
        price: "55",
        img_path: "/baron-menu-designs/Main Pics/15 Eggplant.jpg"
    },

    {
        name: "شمندر، ",
        details: "ريزوتو، شنكليش، جوز مخلل، أعشاب مقرمشة",
        price: "65",
        img_path: "/baron-menu-designs/Main Pics/07 Beetroot.jpg"
    },


]

let mainThreeAr = [

    {
        name: "كولوربيوني، ",
        details: "باستا محشية بالهندبة، زبدة حلبية، صلصة لب الطماطم",
        price: "80",
        img_path: "/baron-menu-designs/Main Pics/19 Culurgiones.jpg"
    },

    {
        name: "كابيليني، ",
        details: "باستا طازجة، روبيان، جبنة البارميجان",
        price: "100",
        img_path: "/baron-menu-designs/Main Pics/18 Capellini.jpg"
    },

    {
        name: "نيودي، ",
        details: "باستا محشية بالقشطة، بقدونس، حبق، جبنة البرميجان، صنوبر، بيواز",
        price: "70",
        img_path: "/baron-menu-designs/Main Pics/21 Gnudi.jpg"
    },

    {
        name: "شيش برك، ",
        details: "لحمة مبهرة، لبن مدخن، طماطم، حبق، صنوبر",
        price: "75",
        img_path: "/baron-menu-designs/Main Pics/20 Shish barak.jpg"
    },

]

let mainFourAr = [

    {
        name: "باو، ",
        details: "صدر غنم، طراطور، دبس الرمان، رمان، شالوت مقلي",
        price: "90",
        img_path: "/baron-menu-designs/Main Pics/23 Bao.jpg"
    },

    {
        name: "سيباس، ",
        details: "فيليه مقلي، كزبرة، خلطة الخضار، ايولي، خبز البريوش",
        price: "75",
        img_path: "/baron-menu-designs/Main Pics/22 Hammour.jpg"
    },
]

let mainFiveAr = [
    
    {
        name: "شانك، ",
        details: "موزة لحم مطبوخ ببطء، بهارات، مفتول، بقدونس، بصل وسماق",
        price: "135",
        img_path: "/baron-menu-designs/Main Pics/25 Shank.jpg",
    },

    {
        name: "خد، ",
        details: "خد واغيو مطبوخ ببطء، زيريكس، طماطم، كرافس مهروس، صلصة جرابيش",
        price: "150",
        img_path: "/baron-menu-designs/Main Pics/gray color.jpg",
    },

    {
        name: "دجاج، ",
        details: "صلصة الشرمولة، ثوم، أرز، خبز مرقوق، صلصة",
        price: "115",
        img_path: "/baron-menu-designs/Main Pics/27 poulet.jpg",
    },

    {
        name: "سيرلوين، ",
        details: "لحم واغو ٤٥٠ غ، زبدة بارون، بطاطا، صلصة الخس الأحمر",
        price: "350",
        img_path: "/baron-menu-designs/Main Pics/26 Sirloin.jpg",
    },

    {
        name: "ريب آي، ",
        details: `واغيو، ٤٥٠ غ، زبدة "بارون"، بطاطس، سلطة الخس الأحمر`,
        price: "350",
        img_path: "/baron-menu-designs/Main Pics/29 Ribeye.jpg",
    },

    {
        name: "بط، ",
        details: "فخذ كونفيت، صدر مشوي، سجق فريكة، رمان",
        price: "155",
        img_path: "/baron-menu-designs/Main Pics/28 Duck.jpg",
    },

    {
        name: "سمك اليوم، ",
        details: "سمكة مشوية ١ كج، صلصة الكراث والكبر، البذور المقرمشة",
        price: "245",
        img_path: "/baron-menu-designs/Main Pics/30 Fish.jpg",
    },
]

export {
    mainOne,
    mainTwo,
    mainThree,
    mainFour,
    mainFive,
    mainOneAr,
    mainTwoAr,
    mainThreeAr,
    mainFourAr,
    mainFiveAr,
}