import styled from "styled-components";

function HabraMenu({}) {
    return (
        <>
            <PageStyled>
                    <iframe
                        src = "https://drive.google.com/file/d/1ceZjPfomsJDxrzibaoV0fXGxrYHFw25f/preview"
                        width = "100%"
                        height = "100%"
                        allow = "autoplay"
                    />
                </PageStyled>
        </>
    )
}

const PageStyled = styled.div`
    position: absolute;
    inset: 0;
    width: 100%;
`

export default HabraMenu;